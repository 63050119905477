<table mat-table [dataSource]="pages" class="mat-elevation-z8 pages-table">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected"
        [indeterminate]="selection.hasValue() && !isAllSelected" color="primary">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>id</th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderCategory}}</th>
    <td mat-cell *matCellDef="let element">{{element.category == 'agile' ? agileShortLabel : seShortLabel}} </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderTitle}}</th>
    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
  </ng-container>

  <ng-container matColumnDef="customer">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderCustomer}}</th>
    <td mat-cell *matCellDef="let element" >{{element.customers.join(", ")}}</td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderRoles}}</th>
    <td mat-cell *matCellDef="let element">{{element.roles.join(", ")}}</td>
  </ng-container>

  <ng-container matColumnDef="tag">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderTags}}</th>
    <td mat-cell *matCellDef="let element"> {{element.tags.join(", ")}} </td>
  </ng-container>

  <ng-container matColumnDef="period">
    <th mat-header-cell *matHeaderCellDef>{{formData.columnHeaderPeriod}}</th>
    <td mat-cell *matCellDef="let element"> {{element.period}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
