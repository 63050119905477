export enum EContactFormFields {
  notAvailable = 'notAvailable',
  emailLabel = 'emailLabel',
  emailPlaceholder = 'emailPlaceholder',
  emailMandatoryError = 'emailMandatoryError',
  emailInvalidError = 'emailInvalidError',
  emailRepeatLabel = 'emailRepeatLabel',
  emailRepeatPlaceholder = 'emailRepeatPlaceholder',
  emailRepeatMandatoryError = 'emailRepeatMandatoryError',
  emailRepeatInvalidError = 'emailRepeatInvalidError',
  emailsDifferentError = 'emailsDifferentError',
  salutationLabel = 'salutationLabel',
  salutationValues = 'salutationValues',
  otherSalutationText = 'otherSalutationText',
  otherSalutationMandatoryError = 'otherSalutationMandatoryError',
  salutationMandatoryError = 'salutationMandatoryError',
  firstNameLabel = 'firstNameLabel',
  firstNamePlaceholder = 'firstNamePlaceholder',
  lastNameLabel = 'lastNameLabel',
  lastNamePlaceholder = 'lastNamePlaceholder',
  perDuLabel = 'perDuLabel',
  subjectLabel = 'subjectLabel',
  contentLabel = 'contentLabel',
  contentLength = 'contentLength',
  buttonClearLabel = 'buttonClearLabel',
  buttonSendLabel = 'buttonSendLabel'
};
