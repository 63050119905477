import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants, DtoFormData, DtoSupportedLanguage, DtoSupportedLanguages, Language } from '@common';
import { CookiesService } from '../../../core/services/cookies.service';
import { FormDataService } from '../../../core/services/form-data.service';
import { I18nService } from '../../../core/services/i18n.service';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';


@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    standalone: false
})
export class LanguageSelectorComponent implements OnInit {

  //#region private properties ------------------------------------------------
  private cookiesService: CookiesService;
  private i18nService: I18nService;
  private dialog: MatDialog;
  private formDataService: FormDataService;
  //#endregion

  //#region public properties -------------------------------------------------
  public supportedLanguages: Array<DtoSupportedLanguage>;
  public get currentLanguage(): Language {
    return this.i18nService.currentLanguage || Constants.defaultLanguage;
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(dialog: MatDialog, cookiesService: CookiesService, i18nService: I18nService, formDataService: FormDataService) {
    this.dialog = dialog;
    this.cookiesService = cookiesService;
    this.i18nService = i18nService;
    this.formDataService = formDataService;
    this.supportedLanguages = new Array<DtoSupportedLanguage>();
  }

  public ngOnInit() {
    this.i18nService.languageChanged.subscribe(() => {
      if (this.supportedLanguages.length === 0) {
        this.i18nService
          .getSupportedLanguages()
          .subscribe((response: DtoSupportedLanguages) => {
            this.supportedLanguages = response.languages
          });
      }
    });
  }
  //#endregion

  //#region UI-triggered methods ----------------------------------------------
  public setLanguage(language: DtoSupportedLanguage) {
    const languageCookie = this.cookiesService.getCookieByName(CookiesService.COOKIE_NAME_LANGUAGE);
    // if we currently have no language cookie, we need to ask for consent
    if (!languageCookie && language.language !== Constants.defaultLanguage) {
      this.formDataService.fetchCookieConfirmationData(language.language).subscribe((data: DtoFormData) => {
        //in case of an error this is a dtopage
        if (!((data.data as unknown) as Record<string, unknown>)['windowTitle']) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: data.data,
            hasBackdrop: true,
            closeOnNavigation: false,
            disableClose: true
          });
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.cookiesService.setCookieByName(CookiesService.COOKIE_NAME_LANGUAGE, language.language);
            }
            this.i18nService.currentLanguage = language.language;
          });
        }
      })
    }
    else if (languageCookie) {
      this.cookiesService.setCookieByName(CookiesService.COOKIE_NAME_LANGUAGE, language.language);
      this.i18nService.currentLanguage = language.language;
    } else {
      this.i18nService.currentLanguage = language.language;
    }
  }

  /**
   * we need to this dynamically, otherwise the icon remains grey because it is on a disabled button
   * @returns der-e-coach green
   */
  public getColor(): string {
    return "#ccff00";
  }

  public isCurrentLanguage(language: DtoSupportedLanguage) {
    return language.language === this.currentLanguage;
  }
  //#endregion
}
