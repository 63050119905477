<ng-container *ngIf="page">
  <ng-container *ngFor="let block of page.blocks">
    <mat-card class="content-page-card">
      <ng-container *ngIf="block.title">
        <mat-card-title class="content-page-card-title">{{block.title}}</mat-card-title>
        <hr class="content-page-hr">
      </ng-container>
      <mat-card-subtitle class="content-page-card-subtitle">
        <ng-container *ngIf="block.wordDefinition">
          <app-content-word-definition [wordDefinition]="block.wordDefinition" class="word-definition">
          </app-content-word-definition>
          <hr class="content-page-hr">
        </ng-container>
      </mat-card-subtitle>
      <mat-card-content class="content-page-card-content">
        <app-content-content [content]="block.content" ></app-content-content>
        <!-- start form section -->
        <ng-container *ngIf="block.form === 'contact-form'">
          <app-contact-form [currentLanguage]="page.language"></app-contact-form>
        </ng-container>
        <ng-container *ngIf="block.form === 'clear-cookie-form'">
          <app-clear-cookie-form [currentLanguage]="page.language"></app-clear-cookie-form>
        </ng-container>
        <ng-container *ngIf="block.form === 'self-service-form'">
          <app-self-service-form [currentLanguage]="page.language"></app-self-service-form>
        </ng-container>
        <!-- end form section -->
      </mat-card-content>
      <mat-card-footer class="content-page-card-footer">
        <ng-container>
          <hr class="content-page-hr">
          <app-content-quotation [quotation]="block.quotation"></app-content-quotation>
        </ng-container>
      </mat-card-footer>
    </mat-card>
  </ng-container>
</ng-container>
