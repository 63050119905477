import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants, DtoSupportedLanguages, Language } from '@common';
import { Observable, BehaviorSubject } from 'rxjs';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  //#region private properties ------------------------------------------------
  private httpClient: HttpClient;
  private _currentLanguage: Language;
  private languageChange: BehaviorSubject<Language>;
  private cookiesService: CookiesService;
  //#endregion

  //#region public properties -------------------------------------------------
  public languageChanged: Observable<Language>;
  //#endregion

  //#region public getter/setter ----------------------------------------------
  public get currentLanguage(): Language {
    return this._currentLanguage;
  }

  public set currentLanguage(value: Language) {
    if (this._currentLanguage !== value) {
      this._currentLanguage = value;
      this.languageChange.next(value);
    }
  }
  //#endregion

  //#region Constructor -------------------------------------------------------
  constructor(httpClient: HttpClient, cookiesService: CookiesService) {
    this.httpClient = httpClient;
    this.cookiesService = cookiesService;
    this._currentLanguage = this.cookiesService.getCookieByName(CookiesService.COOKIE_NAME_LANGUAGE) as Language || Constants.defaultLanguage
    this.languageChange = new BehaviorSubject<Language>(this._currentLanguage);
    this.languageChanged = this.languageChange.asObservable();
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  public getSupportedLanguages(): Observable<DtoSupportedLanguages> {
    return this.httpClient.get<DtoSupportedLanguages>('api/system/languages');
  }
  //#endregion
}
