import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DtoPage } from '@common';
import { Observable, Subject } from 'rxjs';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  //#region private properties ------------------------------------------------
  private httpClient: HttpClient;
  private content: Subject<DtoPage>;
  private _currentPath: string | undefined;
  //#endregion

  public set currentPath(value: string) {
    this._currentPath = value;
    this.loadContent();
  }
  public getContent: Observable<DtoPage>;
  //#region Constructor -------------------------------------------------------
  constructor(httpClient: HttpClient, i18nService: I18nService) {
    this.httpClient = httpClient;
    this._currentPath = '';
    this.content = new Subject<DtoPage>();
    this.getContent = this.content.asObservable();
    i18nService.languageChanged.subscribe(() => this.loadContent());
  }
  //#endregion

  //#region public methods ----------------------------------------------------
  private loadContent(): void {
    if (this._currentPath) {
      this.httpClient
        .get<DtoPage>(`/api/site/page?content=${this._currentPath}`)
        .subscribe((content: DtoPage) => this.content.next(content));
    }
  }
  //#endregion
}
