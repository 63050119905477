<mat-form-field class="chip-list">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #itemChipGrid aria-label="Item selection">
    @for (item of selectedItems(); track $index) {
    <mat-chip-row (removed)="removeItem(item)">
      {{item.displayValue}}
      <button matChipRemove [attr.aria-label]="item.displayValue + 'entfernen'">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <input name="currentItem" placeholder={{addLabel}} #itemInput
    [matChipInputFor]="itemChipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="addItem($event)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItem($event); itemInput.value = ''">
    @for (item of filteredItems(); track item) {
    <mat-option [value]="item">{{item.displayValue}}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
