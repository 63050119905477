import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DtoReferencePageInfo, IKeyDisplayValuePair, Language, ReferenceCategoryType } from '../../../../../../curriculum-common/src';
import { DtoSelfServiceFormData, FormDataService } from '../../../core/services/form-data.service';

@Component({
  selector: 'app-pages-table',
  templateUrl: './pages-table.component.html',
  styleUrl: './pages-table.component.scss',
  standalone: false
})
export class PagesTableComponent implements OnInit {
  //#region @Input/@Output ----------------------------------------------------
  @Input({ required: true }) language!: Language;
  @Input({ required: true }) categories!: Array<IKeyDisplayValuePair<ReferenceCategoryType>>;
  @Input({ required: true }) customers!: Array<IKeyDisplayValuePair<string>>;
  @Input({ required: true }) roles!: Array<IKeyDisplayValuePair<string>>;
  @Input({ required: true }) tags!: Array<IKeyDisplayValuePair<string>>;
  @Input({ required: true }) formData!: DtoSelfServiceFormData;
  @Input({ required: true }) agileShortLabel!: string;
  @Input({ required: true }) seShortLabel!: string;
  @Output() pagesAdded = new EventEmitter<Array<string>>();
  @Output() pagesRemoved = new EventEmitter<Array<string>>();
  //#endregion

  //#region Private fields ----------------------------------------------------
  private readonly formDataService: FormDataService;
  //#endregion

  //#region Public fields -----------------------------------------------------
  public readonly displayedColumns: Array<string>;
  public readonly selection!: SelectionModel<DtoReferencePageInfo>;
  public pages: Array<DtoReferencePageInfo>;
  //#endregion

  //#region Getters/Setters ---------------------------------------------------
  public get isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.pages.length;
    return numSelected == numRows;
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(formDataService: FormDataService) {
    this.formDataService = formDataService;
    this.displayedColumns = new Array<string>("select", "category", "title", "customer", "role", "tag", "period");
    this.selection = new SelectionModel<DtoReferencePageInfo>(true, new Array<DtoReferencePageInfo>());
    this.pages = new Array<DtoReferencePageInfo>();
  }

  public ngOnInit(): void {
    this.selection.changed.subscribe((change: SelectionChange<DtoReferencePageInfo>) => {
      if (change.added.length > 0) {
        this.pagesAdded.emit(change.added.map((page: DtoReferencePageInfo) => page.id));
      }
      if (change.removed.length > 0) {
        this.pagesRemoved.emit(change.removed.map((page: DtoReferencePageInfo) => page.id));
      }
    });
    this.formDataService
      .fetchAvailablePages(
        this.language,
        this.categories.length > 0 ? this.categories.map((category: IKeyDisplayValuePair<ReferenceCategoryType>) => category.key) : undefined,
        this.customers.length > 0 ? this.customers.map((customer: IKeyDisplayValuePair<string>) => customer.key) : undefined,
        this.roles.length > 0 ? this.roles.map((role: IKeyDisplayValuePair<string>) => role.key) : undefined,
        this.tags.length > 0 ? this.tags.map((tag: IKeyDisplayValuePair<string>) => tag.key) : undefined)
      .subscribe((pages: Array<DtoReferencePageInfo>) => {
        this.pages = pages;
        this.selection.select(...this.pages)
      });
  }
  //#endregion

  //#region UI triggered events -----------------------------------------------
  public toggleAllRows(): void {
    if (this.isAllSelected) {
      this.selection.clear()
    } else {
      this.pages.forEach(row => this.selection.select(row));
    }
  }
  //#endregion
}
