import { Component, Input, OnInit } from '@angular/core';
import { DtoFormData, Language } from '@common';
import { CookiesService } from '../../../core/services/cookies.service';
import { DtoClearCookieFormData, FormDataService } from '../../../core/services/form-data.service';

@Component({
  selector: 'app-clear-cookie-form',
  templateUrl: './clear-cookie-form.component.html',
  styleUrls: ['./clear-cookie-form.component.scss'],
  standalone: false
})
export class ClearCookieFormComponent implements OnInit {
  //#region @Input ------------------------------------------------------------
  @Input() currentLanguage!: Language;
  //#endregion ----------------------------------------------------------------

  //#region private properties ------------------------------------------------
  private readonly cookieService: CookiesService;
  private readonly formDataService: FormDataService;
  //#endregion

  //#region public properties -------------------------------------------------
  public hasCookie: boolean;
  public formData: DtoClearCookieFormData | undefined;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(cookieService: CookiesService, formDataService: FormDataService) {
    this.cookieService = cookieService;
    this.formDataService = formDataService;
    this.hasCookie = false;
  }

  public ngOnInit(): void {
    this.cookieService.usingCookiesChanged.subscribe((value: boolean) => this.hasCookie = value);
    this.formDataService.fetchCookieFormData(this.currentLanguage).subscribe((data: DtoFormData) => this.formData = data.data);
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  public clear(): void {
    this.cookieService.deleteCookies(this.formData?.afterClearMessage as string);
  }
  //#endregion
}
