import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EClearCookieFormFields, EContactFormFields, ECookieDialogFields, DtoFilterValues, DtoFormData, DtoReferenceCategory, DtoReferencePageInfo, FormName, Language, ReferenceCategoryType, ESelfServiceFormFields } from '@common';
import { Observable } from 'rxjs';

export type DtoClearCookieFormData = Record<EClearCookieFormFields, string | Array<string>>;
export type DtoContactFormData = Record<EContactFormFields, string | Array<string>>;
export type DtoConfirmationDialogData = Record<ECookieDialogFields, string | Array<string>>;
export type DtoSelfServiceFormData = Record<ESelfServiceFormFields, string | Array<string>>;

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  //#region private properties ------------------------------------------------
  private readonly httpClient: HttpClient;
  private readonly formDataRoot: string;
  private readonly pdfReferenceRoot: string;
  private _processErrorMessage: string;
  //#endregion

  //#region public properties -------------------------------------------------
  public get processErrorMessage(): string {
    return this._processErrorMessage;
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this._processErrorMessage = '';
    this.formDataRoot = 'api/site/forms/';
    this.pdfReferenceRoot = 'api/pdf/references/';
  }
  //#endregion

  //#region public methods ---------------------------------------------------
  public fetchCookieConfirmationData(language: Language): Observable<DtoFormData> {
    return this.httpClient.get<DtoFormData>(this.getFormDataUrl('cookie-dialog', language));
  }

  public fetchContactFormData(language: Language): Observable<DtoFormData> {
    return this.httpClient.get<DtoFormData>(this.getFormDataUrl('contact-form', language));
  }

  public fetchCookieFormData(language: Language): Observable<DtoFormData> {
    return this.httpClient.get<DtoFormData>(this.getFormDataUrl('clear-cookie-form', language));
  }

  public fetchSelfServiceFormData(language: Language): Observable<DtoFormData> {
    return this.httpClient.get<DtoFormData>(this.getFormDataUrl('self-service-form', language));
  }

  public fetchFilterValues(language: Language): Observable<DtoFilterValues> {
    const languageToUse = language == Language.de ? language : Language.en;
    return this.httpClient.get<DtoFilterValues>(`${this.pdfReferenceRoot}filter-values/${languageToUse}`);
  }

  public fetchAvailablePages(
    language: Language,
    categories?: Array<ReferenceCategoryType>,
    customers?: Array<string>,
    roles?: Array<string>,
    tags?: Array<string>
  ): Observable<Array<DtoReferencePageInfo>> {
    const query = new Array<string>();
    const languageToUse = language == Language.de ? language : Language.en;
    if (categories) {
      query.push(`cat=${categories.join(',')}`);
    }
    if (customers) {
      query.push(`cust=${customers.join(",")}`);
    }
    if (tags) {
      query.push(`tag=${tags.join(",")}`);
    }
    if (roles) {
      query.push(`role=${roles.join(",")}`);
    }
    const url = query.length > 0
      ? `${this.pdfReferenceRoot}pages/${languageToUse}?${query.join('&')}`
      : `${this.pdfReferenceRoot}pages/${languageToUse}`;
    return this.httpClient.get<Array<DtoReferencePageInfo>>(url);

  }

  public fetchSupportedCategories(): Observable<Array<DtoReferenceCategory>> {
    return this.httpClient.get<Array<DtoReferenceCategory>>(`${this.pdfReferenceRoot}custom/supported-categories`);
  }
  //#endregion

  //#region private methods --------------------------------------------------
  private getFormDataUrl(formName: FormName, language: Language | null): string {
    return language == null ? `${this.formDataRoot}${formName}` : `${this.formDataRoot}${formName}/${language}`;
  }
  //#endregion
}
