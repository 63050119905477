import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { DtoPage } from '@common';
import { Subscription } from 'rxjs';
import { ContentService } from '../../../core/services/content.service';

@Component({
    selector: 'app-content-page',
    templateUrl: './content-page.component.html',
    styleUrls: ['./content-page.component.scss'],
    standalone: false
})
export class ContentPageComponent implements OnInit, OnDestroy {
  //#region private fields ----------------------------------------------------
  private readonly route: ActivatedRoute;
  private readonly contentService: ContentService;
  private readonly titleService: Title;
  private routeSubscription!: Subscription;
  private contentSubscription!: Subscription;
  //#endregion

  //#region public properties -------------------------------------------------
  public page!: DtoPage;
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(route: ActivatedRoute, titleService: Title, contentService: ContentService) {
    this.route = route;
    this.titleService = titleService;
    this.contentService = contentService;
  }

  public ngOnInit(): void {
    this.routeSubscription = this.contentService.getContent.subscribe((page: DtoPage) => {
      this.page = page;
      const newTitle = page.windowTitle ?
        `Der e-coach: ${page.windowTitle}` :
        'Der e-coach'
      this.titleService.setTitle(newTitle);
    });

    this.contentSubscription = this.route.queryParams.subscribe((params: Params) => {
      const content: string = <string>params['content'] || 'home';
      this.contentService.currentPath = content;
    });
  }

  public ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.contentSubscription.unsubscribe();
  }
  //#endregion
}
