<ng-container *ngIf="formData && languageSelectItems && supportedCategories">
  <div mat-dialog-content class="self-service-form-container">
    <mat-stepper orientation="vertical" class="self-service-stepper" #stepper
      (selectionChange)="onStepperChanged($event)" linear>

      <mat-step [stepControl]="languageForm" [errorMessage]="languageStepError">
        <ng-template matStepLabel>
          <b>{{languageStepTitle}}</b>
        </ng-template>
        <ng-template matStepContent>
          <form [formGroup]="languageForm">
            <ng-container *ngIf="stepper.selectedIndex === 0">
              <div [innerHTML]="languageStepDescription | markdown" class="step-description">{{formData.languageStepDescription}}</div>
              <mat-form-field>
                <mat-label>{{formData.languageControlLabel}}</mat-label>
                <mat-select formControlName="languageControl">
                  @for (language of languageSelectItems; track language) {
                  <mat-option [value]="language">{{language.displayValue}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div>
                <button mat-stroked-button matStepperNext>{{formData.buttonLabelNext}}</button>
              </div>
            </ng-container>
          </form>
        </ng-template>
      </mat-step>

      <mat-step [stepControl]="infoPageForm" [errorMessage]="infoPageStepError">
        <ng-template matStepLabel>
          <b>{{infoPageStepTitle}}</b>
        </ng-template>
        <ng-template matStepContent>
          <form [formGroup]="infoPageForm">
            <ng-container *ngIf="stepper.selectedIndex === 1">
              <div [innerHTML]="infoPageStepDescription | markdown" class="step-description"></div>
              <mat-form-field>
                <mat-label>{{formData.infoPageControlLabel}}</mat-label>
                <mat-select formControlName="infoPageControl">
                  @for (infoPage of infoPageSelectItems; track infoPage) {
                  <mat-option [value]="infoPage">{{infoPage.displayValue}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <div>
                <button mat-stroked-button matStepperPrevious>{{formData.buttonLabelBack}}</button>
                <button mat-stroked-button matStepperNext>{{formData.buttonLabelNext}}</button>
              </div>
            </ng-container>
          </form>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>
          <b>{{preSelectStepTitle}}</b>
        </ng-template>
        <ng-template matStepContent>
          <ng-container *ngIf="stepper.selectedIndex === 2">
            <div [innerHTML]="preSelectStepDescription | markdown" class="step-description"></div>
            <div [innerHTML]="categoryDescription | markdown"></div>
            <app-chip-selector [allItems]="categoryFilterValues" [label]="categoryLabel" [addLabel]="categoryAddLabel"
              (selectionChanged)="selectedCategories = $event"
              [initialSelection]="selectedCategories"></app-chip-selector>
            <div [innerHTML]="customerDescription | markdown"></div>
            <app-chip-selector [allItems]="customerFilterValues" [label]="customerLabel" [addLabel]="customerAddLabel"
              (selectionChanged)="selectedCustomers = $event"
              [initialSelection]="selectedCustomers"></app-chip-selector>
            <div [innerHTML]="roleDescription | markdown"></div>
            <app-chip-selector [allItems]="rolesFilterValues" [label]="roleLabel" [addLabel]="roleAddLabel"
              (selectionChanged)="selectedRoles = $event" [initialSelection]="selectedRoles"></app-chip-selector>
            <div [innerHTML]="tagDescription | markdown"></div>
            <app-chip-selector [allItems]="tagFilterValues" [label]="taglabel" [addLabel]="tagAddLabel"
              (selectionChanged)="selectedTags = $event" [initialSelection]="selectedTags"></app-chip-selector>
            <div>
              <button mat-stroked-button matStepperPrevious>{{formData.buttonLabelBack}}</button>
              <button mat-stroked-button matStepperNext>{{formData.buttonLabelNext}}</button>
            </div>
          </ng-container>
        </ng-template>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>
          <b>{{generateStepLabel}}</b>
        </ng-template>
        <ng-template matStepContent>
          <ng-container *ngIf="stepper.selectedIndex === 3">
            <div [innerHTML]="generateStepDescription | markdown" class="step-description"></div>
            <app-pages-table [agileShortLabel]="agileShortLabel" [categories]="selectedCategories"
              [language]="languageControl.value!.key" [tags]="selectedTags" [customers]="selectedCustomers"
              [roles]="selectedRoles" [seShortLabel]="seShortLabel" (pagesAdded)="pagesAdded($event)"
              [formData]="formData"
              (pagesRemoved)="pagesRemoved($event)"></app-pages-table>
            <div>
              <button mat-stroked-button matStepperPrevious>{{formData.buttonLabelBack}}</button>
              <a mat-stroked-button href="{{generatedPdfLink}}" target="_blank">{{formData.buttonLabelGenerate}}</a>
            </div>
          </ng-container>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</ng-container>
