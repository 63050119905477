import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, computed, EventEmitter, Input, model, OnInit, Output, signal } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { IKeyDisplayValuePair } from '@common';


@Component({
  selector: 'app-chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss'],
  standalone: false
})
export class ChipSelectorComponent<T> implements OnInit{
  //#region @Input/@Output ----------------------------------------------------
  @Input({ required: true }) allItems!: Array<IKeyDisplayValuePair<T>>;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) addLabel!: string;
  @Input() initialSelection?: Array<IKeyDisplayValuePair<T>>;
  @Output() selectionChanged = new EventEmitter<Array<IKeyDisplayValuePair<T>>>();
  //#endregion ----------------------------------------------------------------

  //#region public fields -----------------------------------------------------
  public readonly separatorKeysCodes: Array<number>;
  public readonly currentItem = model<string>();
  public readonly selectedItems = signal<Array<IKeyDisplayValuePair<T>>>(new Array<IKeyDisplayValuePair<T>>());
  public readonly filteredItems = computed(() => {
    const enteredItem = this.currentItem()?.toLowerCase()
    return enteredItem
      ? this.allItems.filter((item: IKeyDisplayValuePair<T>) => item.displayValue.toLowerCase().includes(enteredItem))
      : this.allItems.slice();
  });
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor() {
    this.separatorKeysCodes = [ENTER, COMMA];
  }

  public ngOnInit(): void {
    if (this.initialSelection) {
      this.selectedItems.set(this.initialSelection);
    }
  }
  //#endregion

  //#region chip selection events ---------------------------------------------
  public addItem(event: MatChipInputEvent): void {
    const value = (event.value || null);
    if (value) {
      const itemToAdd = this.allItems.find((item: IKeyDisplayValuePair<T>) => item.displayValue == value)
      if (itemToAdd) {
        this.selectedItems.update((currentItems: Array<IKeyDisplayValuePair<T>>) => [...currentItems, itemToAdd]);
        this.selectionChanged.emit(this.selectedItems());
      }
    }
    this.currentItem.set(undefined);
  }

  public removeItem(itemToRemove: IKeyDisplayValuePair<T>): void {
    this.selectedItems.update((currentItems: Array<IKeyDisplayValuePair<T>>) => {
      const index = currentItems.findIndex((item: IKeyDisplayValuePair<T>) => item.displayValue == itemToRemove.displayValue);
      if (index < 0) {
        return currentItems;
      }
      currentItems.splice(index, 1);
      return [...currentItems];
    });
    this.selectionChanged.emit(this.selectedItems())
  }

  public selectedItem(event: MatAutocompleteSelectedEvent): void {
    const select = this.allItems.find((item: IKeyDisplayValuePair<T>) => item.displayValue == event.option.viewValue);
    if (select) {
      this.selectedItems.update((currentItems: Array<IKeyDisplayValuePair<T>>) => [...currentItems, select]);
      this.selectionChanged.emit(this.selectedItems())
    }
    this.currentItem.set(undefined);
    event.option.deselect();
  }
  //#endregion
}
