export enum ESelfServiceFormFields {
  // Step 1: language step
  languageStepTitle = 'languageStepTitle',
  languageStepDescription = 'languageStepDescription',
  languageStepError = 'languageStepError',
  languageControlLabel = 'languageControlLabel',
  // Step 2: info page category step
  infoPageStepTitle = 'infoPageStepTitle',
  infoPageStepDescription = 'infoPageStepDescription',
  infoPageStepError = "infoPageStepError",
  infoPageControlLabel = 'infoPageControlLabel',
  // Step 3: pre select step
  preSelectStepTitle = 'preSelectStepTitle',
  preSelectStepDescription = 'preSelectStepDescription',
  // Step 3.1 categories chip select
  categoryDescription = 'categoryDescription',
  categoryLabel = 'categoryLabel',
  categoryAddLabel = 'categoryAddLabel',
  // Step 3.2 customers chip select
  customerDescription = 'customerDescription',
  customerLabel = 'customerLabel',
  customerAddLabel = 'customerAddLabel',
  // Step 3.3 roles chip select
  roleDescription = 'roleDescription',
  roleLabel = 'roleLabel',
  roleAddLabel = 'roleAddLabel',
  // Step 3.4 tags chip select
  tagDescription = 'tagDescription',
  tagLabel = 'tagLabel',
  tagAddLabel = 'tagAddLabel',
  // step 4: generate step
  generateStepTitle = 'generateStepTitle',
  generateStepDescription = 'generateStepDescription',
  // table column headers
  columnHeaderCategory = 'columnHeaderCategory',
  columnHeaderTitle = 'columnHeaderTitle',
  columnHeaderCustomer = 'columnHeaderCustomer',
  columnHeaderRoles = 'columnHeaderRoles',
  columnHeaderTags = 'columnHeaderTags',
  columnHeaderPeriod = 'columnHeaderPeriod',
  // Button labels
  buttonLabelBack = 'buttonLabelBack',
  buttonLabelNext = 'buttonLabelNext',
  buttonLabelGenerate = 'buttonLabelGenerate'
};
