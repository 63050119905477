import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { ClearCookieFormComponent } from './components/clear-cookie-form/clear-cookie-form.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContentContentComponent } from './components/content-content/content-content.component';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { ContentQuotationComponent } from './components/content-quotation/content-quotation.component';
import { ContentWordDefinitionComponent } from './components/content-word-definition/content-word-definition.component';
import { ChipSelectorComponent } from './components/self-service-form/chip-selector.component';
import { PagesTableComponent } from './components/self-service-form/pages-table.component';
import { SelfServiceFormComponent } from './components/self-service-form/self-service-form.component';

@NgModule({
  declarations: [
    ChipSelectorComponent,
    ContactFormComponent,
    ContentWordDefinitionComponent,
    ContentQuotationComponent,
    ContentPageComponent,
    ContentContentComponent,
    ClearCookieFormComponent,
    SelfServiceFormComponent,
    PagesTableComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule
  ]
})
export class PagesModule { }
