import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DtoFilterValues, DtoFormData, DtoReferenceCategory, DtoSupportedLanguage, DtoSupportedLanguages, IKeyDisplayValuePair, Language, ReferenceCategoryType } from '@common';
import { DtoSelfServiceFormData, FormDataService } from '../../../core/services/form-data.service';
import { I18nService } from '../../../core/services/i18n.service';


@Component({
  selector: 'app-self-service-form',
  templateUrl: './self-service-form.component.html',
  styleUrls: ['./self-service-form.component.scss'],
  standalone: false,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class SelfServiceFormComponent implements OnInit {
  //#region @Input ------------------------------------------------------------
  @Input({ required: true }) currentLanguage!: Language;
  //#endregion ----------------------------------------------------------------

  //#region private fields ----------------------------------------------------
  private readonly formDataService: FormDataService;
  private readonly i18nService: I18nService;
  private _selectedCategories: Array<IKeyDisplayValuePair<ReferenceCategoryType>>;
  private _selectedCustomers: Array<IKeyDisplayValuePair<string>>;
  // private _selectedLanguage!: IKeyDisplayValuePair<Language>;
  private _selectedRoles: Array<IKeyDisplayValuePair<string>>;
  private _selectedTags: Array<IKeyDisplayValuePair<string>>;
  private _selectedPages: Array<string>;

  //#endregion

  //#region public fields -----------------------------------------------------
  public readonly languageForm: UntypedFormGroup;
  public readonly languageControl: FormControl<IKeyDisplayValuePair<Language> | null>;
  public readonly infoPageForm: UntypedFormGroup;
  public readonly infoPageControl: FormControl<IKeyDisplayValuePair<ReferenceCategoryType> | null>;
  public formData!: DtoSelfServiceFormData;
  public infoPageSelectItems!: Array<IKeyDisplayValuePair<ReferenceCategoryType>>;
  public languageSelectItems!: Array<IKeyDisplayValuePair<Language>>;
  // public selectedInfoCategory: IKeyDisplayValuePair<ReferenceCategoryType> | null;
  public supportedCategories!: Array<DtoReferenceCategory>;
  public agileShortLabel!: string;
  public seShortLabel!: string;
  public agileLongLabel!: string;
  public seLongLabel!: string;
  public rolesFilterValues!: Array<IKeyDisplayValuePair<string>>;
  public tagFilterValues!: Array<IKeyDisplayValuePair<string>>;
  public categoryFilterValues!: Array<IKeyDisplayValuePair<ReferenceCategoryType>>;
  public customerFilterValues!: Array<IKeyDisplayValuePair<string>>;
  //#endregion

  //#region public properties -------------------------------------------------
  public generatedPdfLink: string | null;
  //#endregion

  //#region Public properties: Text blocks and labels -------------------------
  public get languageStepDescription(): string {
    return this.formDataToString(this.formData.languageStepDescription);
  }

  public get languageStepTitle(): string {
    return this.formData.languageStepTitle as string;
  }

  public get languageStepError(): string {
    return this.formData.languageStepError as string;
  }

  public get infoPageStepDescription(): string {
    return this
      .formDataToString(this.formData.infoPageStepDescription)
      .replace("${agile}", this.agileLongLabel)
      .replace("${se}", this.seLongLabel);
  }

  public get infoPageStepTitle(): string {
    return this.formData.infoPageStepTitle as string;
  }

  public get infoPageStepError(): string {
    return this.formData.infoPageStepError as string;
  }

  public get preSelectStepDescription() {
    return this.formDataToString(this.formData.preSelectStepDescription);
  }

  public get preSelectStepTitle(): string {
    return this.formData.preSelectStepTitle as string;
  }

  public get categoryDescription(): string {
    return this.formDataToString(this.formData?.categoryDescription);
  }

  public get categoryLabel(): string {
    return this.formData.categoryLabel as string;
  }

  public get categoryAddLabel(): string {
    return this.formData.categoryAddLabel as string;
  }

  public get customerDescription(): string {
    return this.formDataToString(this.formData?.customerDescription);
  }

  public get customerLabel(): string {
    return this.formData.customerLabel as string;
  }

  public get customerAddLabel(): string {
    return this.formData.customerAddLabel as string;
  }

  public get roleDescription(): string {
    return this.formDataToString(this.formData?.roleDescription);
  }

  public get roleLabel(): string {
    return this.formData.roleLabel as string;
  }

  public get roleAddLabel(): string {
    return this.formData.roleAddLabel as string;
  }

  public get tagDescription(): string {
    return this.formDataToString(this.formData.tagDescription);
  }

  public get taglabel(): string {
    return this.formData.tagLabel as string;
  }

  public get tagAddLabel(): string {
    return this.formData.tagAddLabel as string;
  }

  public get generateStepLabel(): string {
    return this.formData.generateStepTitle as string;
  }

  public get generateStepDescription(): string {
    return this.formDataToString(this.formData.generateStepDescription);
  }
  //#endregion

  //#region Setters for chip selectors ----------------------------------------
  public set selectedCategories(value: Array<IKeyDisplayValuePair<ReferenceCategoryType>>) {
    this._selectedCategories = value;
  }

  public get selectedCategories(): Array<IKeyDisplayValuePair<ReferenceCategoryType>> {
    return this._selectedCategories;
  }

  public set selectedCustomers(value: Array<IKeyDisplayValuePair<string>>) {
    this._selectedCustomers = value;
  }

  public get selectedCustomers(): Array<IKeyDisplayValuePair<string>> {
    return this._selectedCustomers
  }

  public set selectedRoles(value: Array<IKeyDisplayValuePair<string>>) {
    this._selectedRoles = value;
  }

  public get selectedRoles(): Array<IKeyDisplayValuePair<string>> {
    return this._selectedRoles;
  }

  public set selectedTags(value: Array<IKeyDisplayValuePair<string>>) {
    this._selectedTags = value;
  }

  public get selectedTags(): Array<IKeyDisplayValuePair<string>> {
    return this._selectedTags;
  }
  //#endregion

  //#region Constructor & C° --------------------------------------------------
  public constructor(formBuilder: UntypedFormBuilder, formDataService: FormDataService, i18nService: I18nService) {
    this.formDataService = formDataService;
    this.i18nService = i18nService;
    // this.selectedInfoCategory = null;
    this._selectedCustomers = new Array<IKeyDisplayValuePair<string>>();
    this._selectedCategories = new Array<IKeyDisplayValuePair<ReferenceCategoryType>>();
    this._selectedTags = new Array<IKeyDisplayValuePair<string>>();
    this._selectedRoles = new Array<IKeyDisplayValuePair<string>>();
    this._selectedPages = new Array<string>();
    this.generatedPdfLink = null;
    /* eslint-disable-next-line @typescript-eslint/unbound-method */
    this.infoPageControl = new FormControl<IKeyDisplayValuePair<ReferenceCategoryType> | null>(null, [Validators.required]);
    this.infoPageForm = formBuilder.group({
      infoPageControl: this.infoPageControl
    });
    /* eslint-disable-next-line @typescript-eslint/unbound-method */
    this.languageControl = new FormControl<IKeyDisplayValuePair<Language> | null>(null, Validators.required);
    this.languageControl.valueChanges.subscribe((value: IKeyDisplayValuePair<Language> | null) => {
      if (value != null) {
        this.languageChanged(value);
      }
    });
    this.languageForm = formBuilder.group({
      languageControl: this.languageControl
    });
  }

  public ngOnInit(): void {
    this.formDataService.fetchSupportedCategories().subscribe((data: Array<DtoReferenceCategory>) => {
      this.supportedCategories = data;
      this.infoPageSelectItems = this.supportedCategories.map((category: DtoReferenceCategory) => {
        if (category.category == 'agile') {
          this.agileShortLabel = category.shortLabel[this.currentLanguage];
          this.agileLongLabel = category.label[this.languageControl.value?.key || this.currentLanguage];
        } else if (category.category == 'se') {
          this.seShortLabel = category.shortLabel[this.currentLanguage];
          this.seLongLabel = category.label[this.languageControl.value?.key || this.currentLanguage];
        }
        return { key: category.category, displayValue: category.label[this.currentLanguage] };
      });

    });

    this.i18nService.getSupportedLanguages().subscribe((languages: DtoSupportedLanguages) => {
      this.languageSelectItems = languages.languages
        .filter((lng: DtoSupportedLanguage) => lng.language == Language.de || lng.language == Language.en)
        .map((lng: DtoSupportedLanguage) => {
          return { key: lng.language, displayValue: lng.label[this.currentLanguage] }
        });
      const current = this.languageSelectItems.find((lng: IKeyDisplayValuePair<Language>) => lng.key == this.currentLanguage);
      if (current) {
        this.languageControl.setValue(current);
      } else {
        const english = this.languageSelectItems.find((lng: IKeyDisplayValuePair<Language>) => lng.key == Language.en);
        if (english) {
          this.languageControl.setValue(english);
        }
      }
    });

    this.formDataService.fetchSelfServiceFormData(this.currentLanguage).subscribe((data: DtoFormData) => {
      this.formData = data.data;
    });
    this.formDataService
      .fetchFilterValues(this.languageControl.value?.key || this.currentLanguage)
      .subscribe((data: DtoFilterValues) => {
        this.rolesFilterValues = data.roles;
        this.customerFilterValues = data.customers;
        this.tagFilterValues = data.tags;
        this.categoryFilterValues = data.categories;
      });
  }
  //#endregion

  //#region UI triggered methods ----------------------------------------------
  public pagesAdded(added: Array<string>): void {
    this._selectedPages = new Array<string>(...new Set<string>(this._selectedPages.concat(added)));
    this.generatedPdfLink = this.buildGeneratedPdfLink();
  }

  public pagesRemoved(removed: Array<string>): void {
    this._selectedPages = this._selectedPages.filter((id: string) => !removed.includes(id));
    this.generatedPdfLink = this.buildGeneratedPdfLink();
  }

  public onStepperChanged(event: StepperSelectionEvent): void {
    if (event.selectedIndex == 3) {
      this._selectedPages = new Array<string>();
    }
  }
  //#endregion

  //#region Auxiliary methods -------------------------------------------------
  private formDataToString(data: string | Array<string> | undefined): string {
    return data
      ? Array.isArray(data)
        ? data.join('')
        : data
      : ''
  }

  private languageChanged(value: IKeyDisplayValuePair<Language>): void {
    this.infoPageSelectItems = this.supportedCategories.map((category: DtoReferenceCategory) => {
      if (category.category == 'agile') {
        this.agileShortLabel = category.shortLabel[value.key];
        this.agileLongLabel = category.label[this.languageControl.value?.key || this.currentLanguage];
      } else if (category.category == 'se') {
        this.seShortLabel = category.shortLabel[value.key];
        this.seLongLabel = category.label[this.languageControl.value?.key || this.currentLanguage];
      }
      return { key: category.category, displayValue: category.label[value.key] };
    });
    this.formDataService
      .fetchFilterValues(value.key)
      .subscribe((data: DtoFilterValues) => {
        this.rolesFilterValues = data.roles;
        this.customerFilterValues = data.customers;
        this.tagFilterValues = data.tags;
        this.categoryFilterValues = data.categories;
        this._selectedCategories = this.setSelectionInNewLanguage(this._selectedCategories, this.categoryFilterValues);
        this._selectedTags = this.setSelectionInNewLanguage(this.selectedTags, this.tagFilterValues);
        this._selectedRoles = this.setSelectionInNewLanguage(this._selectedRoles, this.rolesFilterValues);
        this.infoPageControl.setValue(this.infoPageSelectItems.find((category: IKeyDisplayValuePair<ReferenceCategoryType>) => category.key == this.infoPageControl.value?.key) || null);
      });
    this.generatedPdfLink = this.buildGeneratedPdfLink();
  }

  private setSelectionInNewLanguage<T>(
    previousSelection: Array<IKeyDisplayValuePair<T>>,
    newItems: Array<IKeyDisplayValuePair<T>>
  ): Array<IKeyDisplayValuePair<T>> {
    const result = new Array<IKeyDisplayValuePair<T>>(
      ...previousSelection
        .filter((item: IKeyDisplayValuePair<T>) => newItems.find((newItem: IKeyDisplayValuePair<T>) => newItem.key == item.key) != undefined)
        .map((item: IKeyDisplayValuePair<T>) => newItems.find((newItem: IKeyDisplayValuePair<T>) => newItem.key == item.key)!)
    );
    return result;
  }

  private buildGeneratedPdfLink(): string | null {
    const now = new Date();

    if (this.languageControl.value && this.infoPageControl.value) {
      let result = `/api/pdf/references/custom/generate/${now.getFullYear()}-${now.getMonth().toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}-`;
      result += this.languageControl.value?.key == Language.de ? 'referenzen' : 'references';
      result += `-${this.languageControl.value?.key}`;
      result += `?info=${this.infoPageControl.value?.key}&ids=${this._selectedPages.join(",")}&language=${this.languageControl.value?.key}`;

      return result;
      // `/api/pdf/references/custom/${now.getFullYear()}.${now.getMonth().toString().padStart(2, '0')}.${now.getDate().toString().padStart(2, '0')}}.references?info=${this.infoPageControl.value?.key}&ids=${this._selectedPages.join(",")}&language=${this.languageControl.value?.key}`;
    } else {
      return null;
    }
  }
  //#endregion
}
